
import PlayerService from '@/services/PlayerService'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Leaderboard',
  data () {
    return {
      playerService: new PlayerService(),
      stats: {} as any,
      loading: false,
      formatter: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      })
    }
  },
  mounted () {
    this.loading = true;
    this.playerService.GetPlayerStats()
      .then((results) => {
        this.stats = results;
        this.stats.sort((firstEl: any, secondEl: any) => { return secondEl.totalProfit - firstEl.totalProfit });
      })
      .finally(() => { this.loading = false });
  }
})
